import BackBtn from 'components/global/BackBtn'
import Layout from 'components/global/Layout';
import { useEffect, useState } from 'react';
import Pagination from 'components/global/pagination';
import Heading from 'components/global/Heading';
import VendorBookings from 'components/vendorsManagement/VendorBookings';
import VendorCars from 'components/vendorsManagement/VendorCars';
import VendorStats from 'components/vendorsManagement/VendorStats';
import DefaultImg from 'assets/images/default.png';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import { baseURL } from 'config/api';
import hideString from 'utils/hideString';


const VendorDetails = () => {
    const { id } = useParams();
    const { user } = useSelector(state => state.auth);
    const [sellerDetails , setSellerDetails] = useState('');
    const queryKey = ['fetch-user-details']
    const { isLoading , data  } = useQuery(queryKey , () => {
        return fetcher(`/user/details/${id}` , user )
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            setSellerDetails(doc);
        }
    }, [data]);

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <Heading title='Vendor Details' icon='user-circle' />
                    <BackBtn />
                </div>
                <div>
                    {
                        isLoading 
                        ? 
                            <Loader />
                        :
                        sellerDetails
                        ? 
                            <div className='flex flex-col bg-gradient rounded-md mt-8 py-8 text-pure items-center justify-center gap-4'>
                                <img 
                                src={sellerDetails?.image ? `${baseURL}/users/${sellerDetails?.image}` : DefaultImg } 
                                alt={sellerDetails?.fullName} 
                                className='w-[100px] h-[100px] rounded-full object-cover border border-white'
                                />
                                <div className='text-center'>
                                    <h3 className='text-lg font-medium'>{sellerDetails?.fullName}</h3>
                                    <p className='my-1'>
                                        {
                                            user?.roles?.includes('admin')
                                            ?
                                                sellerDetails?.email
                                            :
                                                hideString(sellerDetails?.email , 'email')
                                        }
                                    </p>
                                    <p>
                                        {
                                            user?.roles?.includes('admin')
                                            ?
                                                sellerDetails?.phone
                                            :
                                                hideString(sellerDetails?.phone , 'number')
                                        }
    
                                    </p>
                                </div>
                            </div>
                        : 
                            <ItemNotFound message='Seller not found.' />
                    }
                    
                    <div className='mt-6'>
                        <VendorStats seller={sellerDetails} />
                    </div>
                    <div className='mt-16'>
                        <VendorBookings />
                    </div>
                    <div className='mt-16'>
                        <VendorCars />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VendorDetails