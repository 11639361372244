import BackBtn from 'components/global/BackBtn'
import Layout from 'components/global/Layout';
import BookingItem from 'components/bookingManagement/BookingItem';
import DefaultImg from 'assets/images/default.png';
import { useEffect, useState } from 'react';
import Pagination from 'components/global/pagination';
import Heading from 'components/global/Heading';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { baseURL } from 'config/api';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import KycDetails from 'components/userManagement/KycDetails';
import hideString from 'utils/hideString';

const UserDetails = () => {
    const { id } = useParams();
    const { user } = useSelector(state => state.auth);
    const [userDetails , setUserDetails] = useState('');
    const [bookings , setBookings] = useState([]);
    const [currentPage , setCurrentPage] = useState(1);
    const [pages , setPages] = useState(1);
    const [docsCount , setDocsCount] = useState(0);

    const queryKey = ['fetch-user-details']
    const { isLoading : userLoading , data : userData } = useQuery(queryKey , () => {
        return fetcher(`/user/details/${id}` , user )
    });

    const bookingsKey = ['fetch-user-bookings' , currentPage];
    const { isLoading : bookingLoading , data : bookingData } = useQuery(bookingsKey , () => {
        return fetcher(`/booking/customer-bookings/${id}` , user )
    });

    useEffect(() => {
        if(bookingData) {
            const { data : { data : { docs , pages , page , docCount } } } = bookingData;
            setCurrentPage(page);
            setPages(pages);
            setBookings(docs);
            setDocsCount(docCount);
        }
    }, [bookingData]);

    useEffect(() => {
        if(userData) {
            const { data : { data : { doc } } } = userData;
            setUserDetails(doc);
        }
    }, [userData]);


    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <Heading title='User Details' icon='user-circle' />
                    <BackBtn />
                </div>
              
                <div>
                    {
                        userLoading 
                        ? 
                            <Loader />
                        :
                        userDetails
                        ? 
                            <div className='flex flex-col gap-4'>
                                <div className='flex flex-col bg-gradient rounded-md mt-8 py-8 text-pure items-center justify-center gap-4'>
                                    <img 
                                    src={userDetails?.image ? `${baseURL}/users/${userDetails?.image}` : DefaultImg } 
                                    alt={userDetails?.fullName} 
                                    className='w-[100px] h-[100px] rounded-full object-cover border border-white'
                                    />
                                    <div className='text-center'>
                                        <h3 className='text-lg font-medium'>{userDetails?.fullName}</h3>
                                        <p className='my-1'>
                                            {
                                                user?.roles?.includes('admin')
                                                ?
                                                    userDetails?.email
                                                :
                                                    hideString(userDetails?.email , 'email')
                                            }
                                            
                                        </p>
                                        <p>
                                            {
                                                user?.roles?.includes('admin')
                                                ?
                                                    userDetails?.phone
                                                :
                                                    hideString(userDetails?.phone , 'number')
                                            }

                                        </p>
                                    </div>
                                </div>
                                {
                                    userDetails?.kyc && 
                                    <KycDetails kycData={userDetails?.kyc} />
                                }
                            </div>
                        : 
                            <ItemNotFound message='User not found.' />
                    }
                    <div className='mt-6'>
                        <h3 className='text-lg font-semibold '>Bookings</h3>
                        {
                            bookingLoading
                            ? 
                                <Loader />
                            :
                            bookings?.length > 0
                            ? 
                                <div className='flex flex-col gap-6 mt-4'>
                                    {
                                        bookings?.map((item , i) => (
                                            <BookingItem 
                                            key={item?._id} 
                                            booking={item}
                                            />
                                        ))
                                    }
                                    <Pagination
                                    pageCount={pages}
                                    currentPage={currentPage}
                                    setPage={setCurrentPage}
                                    redux={false}
                                    />
                                </div>
                            :
                                <ItemNotFound message='No booking found.' />
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UserDetails