import RequestStatus from 'components/global/RequestStatus';
import Pagination from 'components/global/pagination';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { withdraw_updateDoc } from 'redux/actions/withdrawActions';
import { setCurrentPage } from 'redux/reducers/withdrawReducer';
import confirmBox from 'utils/confirmBox';
import formatAmount from 'utils/formatAmount';
import hideString from 'utils/hideString';

const WithdrawRequestsTable = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedItem , setSelectedItem] = useState(null);
    const { docs , currentPage , pages , updateLoading } = useSelector(state => state.withdraw);
    const { user } = useSelector(state => state.auth);


    const updateHandler = (id , status) => {
        const data = { status };
        const message = `Are you sure? You want to ${status} this request?`
        const onYesClick = () => {
            dispatch(withdraw_updateDoc(id , data));
        }
        confirmBox({ message , onYesClick });
    }

    return (
        <div>
            <div className="shadow-bg overflow-x-auto rounded-lg pb-12">
                <Table className="w-full table-auto overflow-x-auto">
                    <Thead className="border-b bg-gradient text-white ">
                        <Tr className=''>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                User
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Withdraw Details
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Status
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Date
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Action
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody className='text-sm '>
                    {
                        docs?.map((item , i) => (
                            <Tr
                            key={item?._id} 
                            className="border-b  transition duration-300 ease-in-out "
                            >
                                <Td className=" px-6 py-4 whitespace-nowrap sm:text-center text-right text-primary ">
                                    <div className="flex flex-col gap-1 ">
                                        <Link 
                                        to={`/user-management/users/${item?.user?._id}`}
                                        className='underline'
                                        >
                                            {item?.user?.fullName}
                                        </Link>
                                        <span className='text-grayText'>
                                            {
                                                user?.roles?.includes('admin')
                                                ?
                                                    item?.user?.phone
                                                :
                                                    hideString(item?.user?.phone , 'number')
                                            }
                                        </span>
                                    </div>
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap text-center text-lg font-semibold">
                                    <div className='flex flex-col gap-1'>
                                        <span> 
                                            {item?.withdrawBank?.bankName}
                                        </span>
                                        <span> 
                                            {item?.withdrawBank?.accountNo}
                                        </span>
                                        <span> 
                                            {item?.withdrawBank?.accountHolder}
                                        </span>
                                        <span> 
                                            {formatAmount(item?.withdrawAmount)} 
                                        </span>
                                    </div>
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    <div className="flex justify-center">
                                        <RequestStatus status={item?.status} />
                                    </div>
                                </Td>
                            
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                </Td>
                                <Td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                {
                                    updateLoading && selectedItem?._id === item?._id
                                    ? 
                                        <ClipLoader size={20} />
                                    :
                                        <div className="flex items-center justify-center gap-4 text-xl ">
                                            {
                                                item?.status === 'pending' && 
                                                <>
                                                
                                                    <div 
                                                    className='accept'
                                                    title='Approve request'
                                                    onDoubleClick={() => {
                                                        setSelectedItem(item);
                                                        updateHandler(item._id, 'completed');
                                                    }}
                                                    >
                                                        <i className="uil uil-check"></i>
                                                    </div>
                                                    <div 
                                                    className='cancel'
                                                    title='Declined request'
                                                    onDoubleClick={() => {
                                                        setSelectedItem(item);
                                                        updateHandler(item._id, 'declined');
                                                    }}
                                                    >
                                                        <i className="uil uil-times"></i>
                                                    </div>
                                                </>
                                            }
                                            <Link 
                                            to={`/withdraw-requests/${item?._id}`} 
                                            className='text-primary duration-200' 
                                            title='View Details'
                                            >
                                                <i className="uil uil-eye"></i>
                                            </Link>
                                        </div>

                                }
                                </Td>
                            </Tr>
                            ))
                    }
                    
                    </Tbody>
                </Table>
            </div>
            <div className="mt-4">
                <Pagination 
                pageCount={pages}
                currentPage={currentPage}
                setPage={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default WithdrawRequestsTable