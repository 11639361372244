const menuItems = [
    {
        id: 1,
        name: 'Dashboard',
        path: '/',
        icon: 'uil uil-apps' ,
        isHome : true 
    },
    {
        id: 2,
        name: 'User Management',
        path: '/user-management',
        icon: 'uil uil-user'
    },
    {
        id: 3,
        name: 'Vendor Management',
        path: '/vendor-management',
        icon: 'uil uil-clipboard-notes'
    },
    {
        id: 4,
        name: 'Booking Management',
        path: '/booking-management',
        icon: 'uil uil-shopping-cart'
    },
    {
        id: 5,
        name: 'Security Deposits',
        path: '/security-deposits',
        icon: 'uil uil-wallet'
    },
    {
        id: 6,
        name: 'Cars Management',
        path: '/cars-management',
        icon: 'uil uil-chart-pie-alt'
    },
    {
        id: 7,
        name: 'KYC Requests',
        path: '/kyc',
        icon: 'uil uil-file-check'
    },
    {
        id: 8,
        name: 'Withdraw Requests',
        path: '/withdraw-requests',
        icon: 'uil uil-usd-circle'
    },
    {
        id: 9,
        name: 'Insurance Management',
        path: '/insurance-management',
        icon: 'uil uil-shield-plus text-xl'
    },
    {
        id: 19,
        name: 'Role Management',
        path: '/role-management',
        icon: 'uil uil-user-plus'
    },
    {
        id: 10,
        name: 'Blogs',
        path: '/blogs',
        icon: 'uil uil-document-layout-left'
    },
    {
        id: 11,
        name: 'Blog Categories',
        path: '/blog-categories',
        icon: 'uil uil-channel'
    },
    {
        id: 12,
        name: 'Slug Management',
        path: '/slugs',
        icon: 'uil uil-tag-alt'
    },
    {
        id: 13,
        name: 'Discount Coupons',
        path: '/coupons',
        icon: 'uil uil-award-alt'
    },
    {
        id: 14,
        name: 'Send Notification',
        path: '/send-notification',
        icon: 'uil uil-bell'
    },
    {
        id: 15,
        name: 'Settings',
        path: '/settings',
        icon: 'uil uil-setting'
    }
];


export default menuItems;