import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import NameInput from 'components/global/NameInput'
import ValidateInput from 'components/global/ValidateInput'
import FormControl from 'components/global/form/FormControl'
import Axios from 'config/api'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import fetcher from 'utils/fetcher'
import toastError from 'utils/toastError'
import settingsValidation from 'validations/settingsValidation'

const Settings = () => {
    const { user } = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);
    const [settingData , setSettingData] = useState({
        contactNo : '' ,
        whatsAppNo : '' ,
        youtubeLink : '' ,
        twitterLink : '' ,
        facebookLink : '' ,
        instagramLink : '' ,
        serviceCharges : '' ,
        minWithdraw : '' ,
        minRentalDuration : '' , 
        GST : '' ,
        officeAddress : '' ,
        contactEmail : '' , 
        securityFee : '' ,
        landLineNo : ''
    });
    const [isValid , setIsValid] = useState(true);

    const { isLoading , data } = useQuery('fetch-settings' , () => {
        return fetcher('/setting');
    } , { refetchOnWindowFocus : false });

    useEffect(() => {
        if(data){
            const { data : { data : { doc } } } = data;
            setSettingData({...doc})
        }
    }, [data])


    const submitHandler = async formData => {
        if(JSON.stringify(formData) === JSON.stringify(settingData)) {
            return toast.error('You have not made any changes to save.')
        }
        try {
            setLoading(true);

            const { data : { data : { message, doc } } } = await Axios.post('/setting' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            setSettingData(doc);
            toast.success(message);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <Layout>
            <div className="flex items-center justify-between gap-4">
                <Heading 
                title='Settings' 
                showIcon={false} 
                />
                <BackBtn />
            </div>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                    <Formik
                    initialValues={settingData}
                    validationSchema={settingsValidation}
                    onSubmit={submitHandler}
                    enableReinitialize
                    >
                        {
                            formik => {
                                return (
                                    <Form
                                    className='flex flex-col gap-4 mt-4'
                                    >
                                        <div className='shadow-bg p-4 flex flex-col gap-4'>
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Service Charges in %'
                                                placeholder='Ex : 2'
                                                name='serviceCharges'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Minimum Withdraw'
                                                placeholder='Ex : 1000'
                                                name='minWithdraw'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Minimum Rental Duration in Hours'
                                                placeholder='Ex : 10'
                                                name='minRentalDuration'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='GST'
                                                placeholder='Ex : 10'
                                                name='GST'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Security Fee For Booking'
                                                placeholder='Ex : 3000'
                                                name='securityFee'
                                                formik={formik}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className='shadow-bg p-4 flex flex-col gap-4'>
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                label='Youtube Link'
                                                placeholder='Ex : youtube.com/rent-and-drive'
                                                name='youtubeLink'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                label='Facebook Link'
                                                placeholder='Ex : facebook.com'
                                                name='facebookLink'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                label='Twitter Link'
                                                placeholder='Ex : twitter.com'
                                                name='twitterLink'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                label='Instagram Link'
                                                placeholder='Ex : instagram.com'
                                                name='instagramLink'
                                                formik={formik}
                                                />
                                            </div>
                                        </div>
                                        <div className='shadow-bg p-4 flex flex-col gap-4'>
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Contact No'
                                                placeholder='Ex : 918934793'
                                                name='contactNo'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                type='number'
                                                label='Whatsapp No'
                                                placeholder='Ex : 918934793'
                                                name='whatsAppNo'
                                                formik={formik}
                                                />
                                            </div>
                                            <FormControl
                                                control='input'
                                                label='Land Line No'
                                                placeholder='Enter land line number'
                                                name='landLineNo'
                                                formik={formik}
                                            />
                                            <div className="flex gap-4 sm:flex-row flex-col">
                                                <FormControl
                                                control='input'
                                                label='Office Address'
                                                placeholder='Ex : Office#240, Manhattan, NYC, USA'
                                                name='officeAddress'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input'
                                                label='Email for Contact'
                                                placeholder='Enter email for user contact'
                                                name='contactEmail'
                                                formik={formik}
                                                />
                                            </div>
                                        </div>
                                    
                                        <div className='mt-4'>
                                            <button 
                                            className="btn-primary py-2 px-12"
                                            disabled={loading || !formik.isValid}
                                            type='submit'
                                            >
                                                {
                                                    loading 
                                                    ? 
                                                        <ClipLoader size={20} color='white' />
                                                    : 
                                                        'Update'
                                                }
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                        }

                    </Formik>
            }
        </Layout>
    )
}

export default Settings