import AvatarImg from 'assets/images/avatar.png';
import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import { useSelector } from 'react-redux';
import hideString from 'utils/hideString';

const VendorDetails = ({ booking }) => {
    const { user } = useSelector(state => state.auth);

    return (
        <div className='shadow-bg p-4'>
            <div className='flex items-center justify-between'>
                <h3 className="text-lg font-semibold text-primary">
                    Vendor Details
                </h3>
                {/* <RequestStatus status={booking?.seller?.status} /> */}
            </div>
            <div className='mt-6'>
                <div className='flex items-center justify-center'>
                    <img 
                    src={booking?.seller?.image ? baseURL + '/users/' + booking?.seller?.image : AvatarImg} 
                    alt="avatar"
                    className='w-[80px] h-[80px] object-cover'
                     />
                </div>
                <div className='mt-8 flex flex-col gap-3'>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Name :
                        </h6>
                        <span className='text-gray-600'>
                            {booking?.seller?.fullName || 'delete'}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Email :
                        </h6>
                        <span className='text-gray-600'>
                            {
                                user?.roles?.includes('admin')
                                ?
                                    booking?.seller?.email || 'deleted'
                                :
                                    hideString(booking?.seller?.email || 'deleted' , 'email')
                            }
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Phone :
                        </h6>
                        <span className='text-gray-600'>
                            {
                                user?.roles?.includes('admin')
                                ?
                                    booking?.seller?.phone || '---'
                                :
                                    hideString(booking?.seller?.phone || '---' , 'number')
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorDetails