import * as Yup from 'yup';

// Yup validation schema for Role
const roleValidations = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .min(1, 'Name cannot be empty'),
    userIdentifier: Yup.string()
        .matches(
            /^(?:[6-9]\d{9}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
            'Please enter a valid email or phone number.'
        )
        .required('Email or phone is required.'),
    accessibleMenus: Yup.array()
        .of(Yup.string().required('Each menu must be a string'))
        .min(1, 'Accessible menus must contain at least one menu')
        .required('Accessible menus are required'),

});

export default roleValidations