import CarDetailsSlider from 'components/carsManagement/CarDetailsSlider'
import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import RequestStatus from 'components/global/RequestStatus'
import { baseURL } from 'config/api'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { car_getDetails } from 'redux/actions/carActions'
import getDay from 'utils/getDay';
import Magnifier from 'react18-image-magnifier'

const CarDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { docDetails : carDetails , loading  } = useSelector(state => state.car);

    useEffect(() => {
        if(id) {
            dispatch(car_getDetails(id));
        }
    }, [id]);


    return (
        <Layout>
            <div className='pb-12'>
                <BackBtn />
                <div className='flex justify-between items-center mt-4'>
                    <Heading title='Vehicle Details' showIcon={false} />
                    <Link to={`/cars-management/edit/${carDetails?._id}`} className='btn-primary py-1.5 px-8'>
                        Edit Details
                    </Link>
                </div>
                {
                    loading
                    ?
                        <Loader />
                    : 
                    carDetails 
                    ?
                        <div className='shadow-bg p-4 mt-8 sm:text-base text-sm'>
                            <div className=''>
                                <div>
                                    <CarDetailsSlider carDetails={carDetails} />
                                </div>
                                <div className='mt-4'>
                                    <div className='flex justify-between items-center'>
                                        <h1 className='text-xl font-semibold flex items-center gap-2 text-black'>
                                            <i className="uil uil-car"></i>
                                            {carDetails?.name}
                                        </h1>
                                        <p className='font-semibold text-sm text-green-500'>
                                            {/* {carDetails?.isActive ? 'Available' : 'Not Available'} */}
                                            <RequestStatus status={carDetails?.status} />
                                        </p>
                                    </div>
                                    <div className='mt-8 text-dark flex flex-col gap-2'>
                                        <div className='flex items-center justify-between '>
                                            <h6 className='font-medium'>Rent Per Hour :</h6>
                                            <p>
                                                <span>&#8377;</span>
                                                <span> {carDetails?.rentPerHour}</span>
                                            </p>
                                        </div>
                                        <div className='flex items-center justify-between '>
                                            <h6 className='font-medium'>
                                                Location 
                                            </h6>
                                            <div className='text-right'>
                                                <a 
                                                href={`https://www.google.com/maps/search/?api=1&query=${carDetails?.location?.lat},${carDetails?.location?.lng}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className='underline text-primary'
                                                >
                                                    {carDetails?.location?.address}
                                                </a>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between '>
                                            <h6 className='font-medium'>
                                                Drop off Location 
                                            </h6>
                                            <div className='text-right'>
                                                <a 
                                                href={`https://www.google.com/maps/search/?api=1&query=${carDetails?.dropOffLocation?.lat},${carDetails?.dropOffLocation?.lng}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className='underline text-primary'
                                                >
                                                    {carDetails?.dropOffLocation?.address}
                                                </a>
                                            </div>
                                            
                                        </div>
                                        <div className='flex items-center justify-between '>
                                                <h6 className='font-medium'>
                                                    Vendor 
                                                </h6>
                                                <Link to={`/vendor-management/vendors/${carDetails?.user?._id}`} className='text-base font-semibold underline'> 
                                                    {carDetails?.user?.fullName}
                                                </Link>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8">
                                <h3 className='font-semibold text-xl'>Features</h3>

                                <div className='mt-6 shadow-bg p-4'>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Brand</h6>
                                        <p>
                                            {carDetails?.make}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Model</h6>
                                        <p>
                                            {carDetails?.model}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Vehicle Type</h6>
                                        <p>
                                            {carDetails?.vehicleType}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Transmission</h6>
                                        <p>
                                            {carDetails?.transmissionType}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Fuel Type</h6>
                                        <p>
                                            {carDetails?.fuelType}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>License Plate</h6>
                                        <p>
                                            {carDetails?.licensePlate}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Seats</h6>
                                        <p>
                                            {carDetails?.seats}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>Doors</h6>
                                        <p>
                                            {carDetails?.doors}
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>
                                            Air Conditioning
                                        </h6>
                                        <p>
                                            {
                                                carDetails?.airConditioning ? 'Yes' : 'No' || '-'
                                            }
                                        </p>
                                    </div>
                                    <div className='border-b py-4 flex items-center justify-between'>
                                        <h6 className='font-semibold'>
                                            Audio System
                                        </h6>
                                        <p>
                                            {carDetails?.audioSystem  || '-'}
                                        </p>
                                    </div>
                                    <div className='border-b py-4'>
                                        <h6 className='font-semibold'>
                                            Pickup Instructions
                                        </h6>
                                        <p className='text-gray-500 text-sm mt-2'>
                                            {carDetails?.pickupInstructions || '-'}
                                        </p>
                                    </div>
                                    {
                                        carDetails?.RC && 
                                        <div className='border-b py-4'>
                                            <h6 className='font-semibold'>
                                                RC (Registration Certificate)
                                            </h6>
                                            <div className="flex items-center justify-center mt-4">
                                                <Magnifier 
                                                src={baseURL + '/RC/' + carDetails?.RC} 
                                                alt="RC"
                                                width={500}
                                                zoomFactor={0.75}
                                                mgWidth={300}
                                                mgHeight={300}
                                                className='md:w-[500px] w-[300px] h-auto' 
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        carDetails?.POC &&
                                        <div className='border-b py-4'>
                                            <h6 className='font-semibold'>
                                                PUC
                                            </h6>
                                            <div className="flex items-center justify-center mt-4">
                                                <Magnifier 
                                                src={baseURL + '/POC/' + carDetails?.POC} 
                                                alt="POC"
                                                width={500}
                                                zoomFactor={0.75}
                                                mgWidth={300}
                                                mgHeight={300}
                                                className='md:w-[500px] w-[300px] h-auto' 
                                                />
                                            </div>
                                        </div>
                                    }
                                     {
                                        carDetails?.insurance &&
                                        <div className=' py-4'>
                                            <h6 className='font-semibold'>
                                                Insurance
                                            </h6>
                                            <div className="flex items-center justify-center mt-4">
                                                <Magnifier 
                                                src={baseURL + '/insurance/' + carDetails?.insurance} 
                                                alt="Insurance"
                                                width={500}
                                                zoomFactor={0.75}
                                                mgWidth={300}
                                                mgHeight={300}
                                                className='md:w-[500px] w-[300px] h-auto' 
                                                />
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    :
                        <ItemNotFound message='Car details not found.' />
                }
            </div>
        </Layout>
    )
}

export default CarDetails