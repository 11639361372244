import { createSlice } from "@reduxjs/toolkit";

const securityDepositSlice = createSlice({
    name : 'securityDeposit' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        loading : false , 
        updateLoading : false , 
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        status : '' ,
    } , 
    reducers : {
        setStats (state , action) {
            const { docs , docsCount , page , pages } = action.payload;
            state.docs = docs;
            state.docsCount = docsCount;
            state.currentPage = page;
            state.pages = pages;
        },
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,

        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setStatus (state , action) {
            state.status = action.payload;
        } ,
    
    }
});

export const { 
    setStats , setDocDetails , updateDoc , setLoading ,  setUpdateLoading , setCurrentPage , setStatus 
} = securityDepositSlice.actions;

export default securityDepositSlice.reducer;