import Sidebar from "components/global/sidebar";
import { Routes , Route, useLocation, Navigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import Dashboard from "pages/dashboard";
import Login from "pages/auth/Login";
import Cars from "pages/carsManagement";
import AddNewCar from "pages/carsManagement/AddNewCar";
import EditCar from "pages/carsManagement/EditCar";
import CarDetails from "pages/carsManagement/CarDetails";
import Profile from "pages/profile";
import Users from "pages/userManagement";
import AddUser from "pages/userManagement/AddUser";
import EditUser from "pages/userManagement/EditUser";
import UserDetails from "pages/userManagement/UserDetails";
import Bookings from "pages/bookingManagement";
import BookingDetails from "pages/bookingManagement/BookingDetails";
import Vendors from "pages/vendorsManagement";
import AddVendor from "pages/vendorsManagement/AddVendor";
import EditVendor from "pages/vendorsManagement/EditVendor";
import VendorDetails from "pages/vendorsManagement/VendorDetails";
import Coupons from "pages/coupons";
import AddCoupon from "pages/coupons/AddCoupon";
import EditCoupon from "pages/coupons/EditCoupon";
import SendNotification from "pages/notifications/SendNotification";
import Settings from "pages/settings";
import PasswordSetup from "pages/passwordSetup";
import { useApi } from "config/api";
import ProtectedRoute from "ProtectedRoute";
import VerifyOtp from "pages/auth/VerifyOtp";
import { useSelector } from "react-redux";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import UploadPickUpPhotos from "pages/bookingManagement/UploadPickUpPhotos";
import UploadDropOffPhotos from "pages/bookingManagement/UploadDropOffPhotos";
import WithdrawRequests from "pages/withdrawRequests";
import WithdrawDetails from "pages/withdrawRequests/WithdrawDetails";
import Kyc from "pages/kyc";
import KycDetails from "pages/kyc/KycDetails";
import ManageAvailability from "pages/carsManagement/ManageAvailability";
import BlogCategories from "pages/blogCategories";
import Blogs from "pages/blogs";
import AddNewBlog from "pages/blogs/AddNewBlog";
import EditBlog from "pages/blogs/EditBlog";
import BlogDetails from "pages/blogs/BlogDetails";
import Slugs from "pages/slugManagement";
import AddNewSlug from "pages/slugManagement/AddNewSlug";
import EditSlug from "pages/slugManagement/EditSlug";
import InsuranceManagement from "pages/insuranceManagement";
import AddInsurance from "pages/insuranceManagement/AddInsurance";
import EditInsurance from "pages/insuranceManagement/EditInsurance";
import NotificationToast from "components/global/NotificationToast";
import { onMessageListener } from "utils/firebase";
import { useEffect, useState } from "react";
import Notifications from "pages/notifications";
import SecurityDeposits from "pages/securityDeposits";
import RoleManagement from "pages/roleManagement";
import AddNewRole from "pages/roleManagement/AddNewRole";
import EditRole from "pages/roleManagement/EditRole";
import menuItems from "constants/sidebar.constants";


function App() {
    const api = useApi();
    const { user } = useSelector(state => state.auth);
    const location = useLocation();

    const isAuthPage = () => {
        if(location.pathname === '/login' || location.pathname === '/verify-otp' || location.pathname === '/forgot-password' || location.pathname === '/reset-password') {
            return true;
        }
        return false;
    }

    const [notification, setNotification] = useState({ title: "", body: "" , redirectUrl : ''});
    const notify = () => toast(<NotificationToast notification={notification} /> , {
        position : "bottom-right" ,
        autoClose : 5000 ,
        hideProgressBar : false
    });

    useEffect(() => {
        if (notification?.title) {
            notify();
        }
    }, [notification]);

    onMessageListener()
    .then((payload) => {
        setNotification({ 
            title: payload?.notification?.title, 
            body: payload?.notification?.body ,
            redirectUrl : payload?.data?.redirectUrl 
        });
    })
    .catch((err) => console.log("failed: ", err));


    const navigateLoggedInUser = () => {
        if(user?.roles?.includes('admin')) {
            return '/'
        }
        const firstMenuName = user?.customRole?.accessibleMenus[0];
        const matchedMenu = menuItems.find(item => item.name === firstMenuName);
        const url = matchedMenu?.path; 
        return url;
    }

    return (
        <div className="space">
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={3000}
                closeOnClick
                pauseOnHover
            />
            {!isAuthPage() && <Sidebar />}
            <Routes>
                <Route path='/' element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route 
                path='/login' 
                element={user ? <Navigate to={navigateLoggedInUser()} /> : <Login />} 
                />
                <Route 
                path='/verify-otp' 
                element={<VerifyOtp />} 
                />
                <Route 
                path='/forgot-password' 
                element={user ? <Navigate to={navigateLoggedInUser()} /> : <ForgotPassword />} 
                />
                <Route 
                path='/reset-password' 
                element={user ? <Navigate to={navigateLoggedInUser()} /> : <ResetPassword />} 
                />
                <Route 
                path='/user-management'
                element={ 
                    <ProtectedRoute>
                        <Users />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/profile'
                element={ 
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/user-management/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddUser />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/user-management/edit-user/:id'
                element={   
                    <ProtectedRoute>
                        <EditUser />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/user-management/users/:id'
                element={ 
                    <ProtectedRoute>
                        <UserDetails />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/vendor-management'
                element={ 
                    <ProtectedRoute>
                        <Vendors />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/vendor-management/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddVendor />
                    </ProtectedRoute>}
                />
                <Route 
                path='/vendor-management/edit-vendor/:id'
                element={ 
                    <ProtectedRoute>
                        <EditVendor />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/vendor-management/vendors/:id'
                element={ 
                    <ProtectedRoute>
                        <VendorDetails />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/booking-management'
                element={ 
                    <ProtectedRoute>
                        <Bookings />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/booking-management/upload-pick-up-photos/:id'
                element={
                    <ProtectedRoute>
                        <UploadPickUpPhotos />
                    </ProtectedRoute>}
                />
                <Route 
                path='/booking-management/upload-drop-off-photos/:id'
                element={
                    <ProtectedRoute>
                        <UploadDropOffPhotos />
                    </ProtectedRoute>}
                />
                <Route 
                path='/booking-management/booking-details/:id'
                element={ 
                    <ProtectedRoute>
                        <BookingDetails />
                    </ProtectedRoute>
                }
                />
                 <Route 
                path='/security-deposits'
                element={ 
                    <ProtectedRoute>
                        <SecurityDeposits />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/cars-management'
                element={ 
                    <ProtectedRoute>
                        <Cars />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/cars-management/manage-availability/:id'
                element={
                    <ProtectedRoute>
                        <ManageAvailability />
                    </ProtectedRoute>}
                />
                <Route 
                path='/cars-management/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddNewCar />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/cars-management/edit/:id'
                element={ 
                    <ProtectedRoute>
                        <EditCar />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/cars-management/details/:id'
                element={ 
                    <ProtectedRoute>
                        <CarDetails />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/coupons'
                element={ 
                    <ProtectedRoute>
                        <Coupons />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/coupons/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddCoupon />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/coupons/edit/:id'
                element={ 
                    <ProtectedRoute>
                        <EditCoupon />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/send-notification'
                element={ 
                    <ProtectedRoute>
                        <SendNotification />
                    </ProtectedRoute>
                }
                />               
                <Route 
                path='/notifications'
                element={ 
                    <ProtectedRoute>
                        <Notifications />
                    </ProtectedRoute>
                }
                />               
                {/* <Route 
                path='/notifications/edit/:id'
                element={ 
                    <ProtectedRoute>
                        <EditNotification />
                    </ProtectedRoute>
                }
                />                */}
                <Route 
                path='/settings'
                element={ 
                    <ProtectedRoute>
                        <Settings />
                    </ProtectedRoute>
                }
                />               
                <Route 
                path='/update-password'
                element={ 
                    <ProtectedRoute>
                        <PasswordSetup />
                    </ProtectedRoute>
                }
                />      
                <Route 
                path='/withdraw-requests'
                element={ 
                    <ProtectedRoute>
                        <WithdrawRequests />
                    </ProtectedRoute>
                }
                />        
                <Route 
                path='/withdraw-requests/:id'
                element={ 
                    <ProtectedRoute>
                        <WithdrawDetails />
                    </ProtectedRoute>
                }
                />     
                <Route 
                path='/kyc'
                element={ 
                    <ProtectedRoute>
                        <Kyc />
                    </ProtectedRoute>
                }
                />   
                <Route 
                path='/kyc/:id'
                element={ 
                    <ProtectedRoute>
                        <KycDetails />
                    </ProtectedRoute>
                }
                />  
                <Route 
                path='/blogs'
                element={ 
                    <ProtectedRoute>
                        <Blogs />
                    </ProtectedRoute>
                }
                />     
                <Route 
                path='/blogs/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddNewBlog />
                    </ProtectedRoute>
                }
                />   
                <Route 
                path='/blogs/edit/:id'
                element={ 
                    <ProtectedRoute>
                        <EditBlog />
                    </ProtectedRoute>
                }
                />  
                <Route 
                path='/blogs/:id'
                element={ 
                    <ProtectedRoute>
                        <BlogDetails />
                    </ProtectedRoute>
                }
                />     
                <Route 
                path='/blog-categories'
                element={ 
                    <ProtectedRoute>
                        <BlogCategories />
                    </ProtectedRoute>
                }
                />     
                 <Route 
                path='/slugs'
                element={ 
                    <ProtectedRoute>
                        <Slugs />
                    </ProtectedRoute>
                }
                />     
                <Route 
                path='/slugs/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddNewSlug />
                    </ProtectedRoute>
                }
                />  
                <Route 
                path='/slugs/edit/:slug'
                element={ 
                    <ProtectedRoute>
                        <EditSlug />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/insurance-management'
                element={ 
                    <ProtectedRoute>
                        <InsuranceManagement />
                    </ProtectedRoute>
                }
                />    
                <Route 
                path='/insurance-management/add'
                element={ 
                    <ProtectedRoute>
                        <AddInsurance />
                    </ProtectedRoute>
                }
                />  
                <Route 
                path='/insurance-management/edit/:id'
                element={ 
                    <ProtectedRoute>
                        <EditInsurance />
                    </ProtectedRoute>
                }
                />    
                <Route 
                path='/role-management'
                element={ 
                    <ProtectedRoute>
                        <RoleManagement />
                    </ProtectedRoute>
                }
                />    
                <Route 
                path='/role-management/add-new'
                element={ 
                    <ProtectedRoute>
                        <AddNewRole />
                    </ProtectedRoute>
                }
                />  
                <Route 
                path='/role-management/edit/:id'
                element={ 
                    <ProtectedRoute>
                        <EditRole />
                    </ProtectedRoute>
                }
                />         
                {/* <Route 
                path='/analytics'
                element={<AnalyticsAndReporting />}
                /> */}
            </Routes>
        </div>
    );
}

export default App;
