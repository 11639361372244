import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import vendorReducer from "./reducers/vendorReducer";
import bookingReducer from "./reducers/bookingReducer";
import carReducer from "./reducers/carReducer";
import couponReducer from "./reducers/couponReducer";
import notificationReducer from "./reducers/notificationReducer";
import withdrawReducer from "./reducers/withdrawReducer";
import kycReducer from "./reducers/kycReducer";
import blogCategoryReducer from "./reducers/blogCategoryReducer";
import blogReducer from "./reducers/blogReducer";
import slugReducer from "./reducers/slugReducer";
import insuranceReducer from "./reducers/insuranceReducer";
import securityDepositsReducer from "./reducers/securityDepositsReducer";
import roleReducer from "./reducers/roleReducer";


const store = configureStore({
    reducer: {
        auth : authReducer ,
        user : userReducer ,
        vendor : vendorReducer , 
        booking : bookingReducer , 
        car : carReducer , 
        coupon : couponReducer ,
        notification : notificationReducer ,
        withdraw : withdrawReducer ,
        kyc : kycReducer ,
        blogCategory : blogCategoryReducer , 
        blog : blogReducer ,
        slug : slugReducer ,
        insurance : insuranceReducer , 
        securityDeposit : securityDepositsReducer ,
        role : roleReducer
    },
});

export default store;