import BackBtn from 'components/global/BackBtn'
import FormControl from 'components/global/form/FormControl'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import menuItems from 'constants/sidebar.constants'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { role_createDoc, role_edit, role_getDetails } from 'redux/actions/roleActions'
import roleValidations from 'validations/roleValidations'

const initValues = {
    name: '',
    accessibleMenus: []
}

const EditRole = () => {
    const { id } = useParams();
    const dispatch = useDispatch() ;
    const navigate = useNavigate();
    const { updateLoading , docDetails } = useSelector(state => state.role);

    const initValues = {
        name: docDetails?.name || '',
        accessibleMenus: docDetails?.accessibleMenus || [] ,
        userIdentifier : docDetails?.user?.email || ''
    }
    

    useEffect(() => {
        if(!docDetails) {
            dispatch(role_getDetails(id));
        }
    } , [docDetails , dispatch , id]);

    const handleSubmit = (formData) => {
        const body = {...formData};
        dispatch(role_edit(id , body , navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div className='flex items-center gap-4'>
                        <Heading title='Update Role' />
                    </div>
                    <BackBtn />
                </div>
            </div>
            <div className='shadow-bg p-4 mt-6'>
                <Formik
                    initialValues={initValues}
                    validationSchema={roleValidations}
                    onSubmit={handleSubmit}
                >
                    {
                        (formik) => {
                            return (
                                <Form
                                    className=' '
                                >
                                    <div className='flex flex-col gap-4 '>
                                        <div className='flex gap-4 md:flex-row flex-col '>
                                            <FormControl
                                                control='input'
                                                label='Name'
                                                placeholder='Enter name of the role'
                                                name='name'
                                                formik={formik}
                                            />
                                            <FormControl
                                                control='input'
                                                label='Email or Phone'
                                                placeholder='Enter user email or phone no'
                                                name='userIdentifier'
                                                formik={formik}
                                            />
                                        </div>
                                        <FormControl
                                            control='checkbox'
                                            label='Accessible Menus'
                                            options={menuItems.map(item => ({ value : item.name , key : item?.name }))}
                                            name='accessibleMenus'
                                            formik={formik}
                                            optionsContainerClassName='grid grid-cols-3 gap-4'
                                        />

                                        
                                    </div>
                                    <div className='mt-8'>
                                        <button
                                            className="btn-primary py-2 px-10"
                                            disabled={updateLoading || !formik.isValid}
                                        >
                                            {
                                                updateLoading
                                                ?
                                                    <ClipLoader size={20} />
                                                :
                                                    'Submit'
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )
                        }
                    }

                </Formik>
            </div>
        </Layout>
    )
}

export default EditRole