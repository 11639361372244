import AvatarImg from 'assets/images/avatar.png';
import RequestStatus from 'components/global/RequestStatus';
import KycDetails from 'components/userManagement/KycDetails';
import { baseURL } from 'config/api';
import { useSelector } from 'react-redux';
import hideString from 'utils/hideString';

const UserDetails = ({ booking }) => {
    const { user } = useSelector(state => state.auth);

    return (
        <div className='shadow-bg p-4'>
            <div className='flex items-center justify-between'>
                <h3 className="text-lg font-semibold text-primary">
                    User Details
                </h3>
                <RequestStatus status={booking?.customer?.status} />
            </div>
            <div className='mt-6'>
                <div className='flex items-center justify-center'>
                    <img 
                    src={booking?.customer?.image ? (baseURL + '/users/' + booking?.customer?.image) : AvatarImg} 
                    alt="avatar"
                    className='w-[80px] h-[80px] object-cover'
                    />
                </div>
                <div className='mt-8 flex flex-col gap-3'>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Name :
                        </h6>
                        <span className='text-gray-600'>
                            {booking?.customer?.fullName}
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Email :
                        </h6>
                        <span className='text-gray-600'>
                            {
                                user?.roles?.includes('admin')
                                ?
                                    booking?.customer?.email
                                :
                                    hideString(booking?.customer?.email , 'email')
                            }
                        </span>
                    </div>
                    <div className='text-sm flex items-center gap-2'>
                        <h6 className='font-semibold w-[60px]'>
                            Phone :
                        </h6>
                        <span className='text-gray-600'>
                            {
                                user?.roles?.includes('admin')
                                ?
                                    booking?.customer?.phone
                                :
                                    hideString(booking?.customer?.phone , 'number')
                            }
                        </span>
                    </div>
                    <div className='mt-2'>
                    {
                        booking?.customer?.kyc 
                        ? 
                            <KycDetails kycData={booking?.customer?.kyc} heading='User kyc' />
                        : 
                            <p className="text-red-500">This user has not completed KYC yet.</p>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetails