import './styles.css';
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/authActions';
import { ClipLoader } from 'react-spinners';
import menuItems from 'constants/sidebar.constants';

const Sidebar = () => {
    const { loading , user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarRef = useRef();
    const location = useLocation();
    const { showDrawer , setShowDrawer } = useDrawerContext();

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

   const logoutHandler = () => {
        dispatch(logout(navigate));
   }

    return (
        <div className=''>
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0 md:left-0 -left-[200%] w-[270px]  overflow-auto py-4 z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='overflow-auto'>
                    <div className='flex items-center justify-center border-b pb-4 text-gradient text-2xl font-bold'>
                        <Link to='/'>
                            Rent&Drive                       
                        </Link>
                    </div>
                    <ul className='sideMenu-list mt-6 text-dark min-h-screen h-full'>
                        {
                            user?.roles?.includes('admin')
                            ?
                                menuItems
                                ?.map(item => (
                                    <li 
                                    className={`${isActive(item?.isHome ? '' : item?.path?.slice(1) , item.isHome) ? 'active' : ''} sideMenu-item`}
                                    >
                                        <Link to={item?.path}>   
                                            <i className={item?.icon}></i>
                                            <span>
                                                {item?.name}
                                            </span>
                                        </Link>
                                    </li>
                                ))
                            :
                                menuItems
                                ?.filter(item => user?.customRole?.accessibleMenus?.includes(item.name))
                                ?.map(item => (
                                    <li 
                                    className={`${isActive(item?.isHome ? '' : item?.path?.slice(1) , item.isHome) ? 'active' : ''} sideMenu-item`}
                                    >
                                        <Link to={item?.path}>   
                                            <i className={item?.icon}></i>
                                            <span>
                                                {item?.name}
                                            </span>
                                        </Link>
                                    </li>
                                ))
                        } 
                        <li 
                        className={`sideMenu-item signout`}
                        onClick={logoutHandler}
                        >
                            {
                                loading
                                ? 
                                    <ClipLoader size={20} color='black' />
                                : 
                                    <>
                                        <i className="uil uil-signout"></i>
                                        <span>Sign Out</span>
                                    </>
                            }
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    )
}

export default Sidebar

