import PopupLayout from 'components/global/PopupLayout'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowAssignRolePopup } from 'redux/reducers/roleReducer';
import { PulseLoader } from 'react-spinners';
import { Form, Formik } from 'formik';
import FormControl from 'components/global/form/FormControl';
import * as Yup from 'yup'
import Axios from 'config/api';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';

const assingRoleValidations = Yup.object({
    userIdentifier: Yup.string()
    .matches(
        /^(?:[6-9]\d{9}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        'Please enter a valid email or phone number.'
    )
    .required('Email or phone is required.'),
});


const initValues = {
    userIdentifier : '' ,
}

const AssignRolePopup = () => {
    const dispatch = useDispatch();
    const [loading , setLoading] = useState(false);
    const { showAssignRolePopup , selectedDoc } = useSelector(state => state.role);
    const { user } = useSelector(state => state.auth);


    const handleSubmit = async (formData) => {
        const body = {...formData , roleId : selectedDoc?._id }
        try {
            setLoading(true);
            const { data : { data : { message } } } = await Axios.post(`/role/assign` , body , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            dispatch(setShowAssignRolePopup(false));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <PopupLayout
            setShowPopup={setShowAssignRolePopup}
            redux
        >
            <center>
                <h3 className='text-xl font-semibold text-gradient'>Assign Role</h3>
            </center>
            <Formik
                initialValues={initValues}
                validationSchema={assingRoleValidations}
                onSubmit={handleSubmit}
            >
                {
                    (formik) => {
                        return (
                            <Form
                                className='flex flex-col gap-4 mt-8'
                            >
                                <FormControl
                                    control='input'
                                    label='Email or Phone'
                                    placeholder='Enter user email or phone'
                                    name='userIdentifier'
                                    formik={formik}
                                />
                                <div className='mt-10'>
                                    <button
                                        type='submit'
                                        className='btn-primary py-2.5 px-12'
                                        disabled={loading || !formik.isValid}
                                    >
                                        {
                                            loading
                                            ?
                                                <PulseLoader size={10} color='#fff' />
                                            :
                                                'Assign'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </PopupLayout>
    )
}

export default AssignRolePopup