import React, { memo, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { user_edit } from 'redux/actions/userActions';
import RequestStatus from 'components/global/RequestStatus';
import confirmBox from 'utils/confirmBox';
import hideString from 'utils/hideString';

const UsersTable = ({ selectedUsers , setSelectedUsers , selectAll , setSelectAll }) => {
    const dispatch = useDispatch();
    const dropMenuRef = useRef(null);
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);

    const { docs : users , blockLoading , deleteLoading } = useSelector(state => state.user);
    const { user } = useSelector(state => state.auth)
  
    const selectUserHandler = (event, userId) => {
        if (event.target.checked) {
            setSelectedUsers([...selectedUsers, userId]);
        } else {
            setSelectedUsers(selectedUsers.filter(id => id !== userId));
        }
    };
  
    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
        setSelectedUsers(event.target.checked ? users?.map(user => user?._id) : []);
    };
    
    useClickOutside(dropMenuRef , () => setShowDropMenu(false));

    const blockUserHandler = async (id , status) => {
        const message = `Are you sure? You want to ${status === 'active' || status === 'inActive' ? 'unblock' : 'block'} this user`;
        const onYesClick = async () => {
            await dispatch(user_edit(id, { status }))
            setShowDropMenu(false);
        } 
        confirmBox({ message , onYesClick});
    }

    const deleteHandler = (userId , status) => {
        const message = `Are you sure? You want to ${status === 'active' || status === 'inActive' ? 'activate' : 'delete'} this user`;
        const onYesClick = async () => {
            await dispatch(user_edit(userId, { status }))
            setShowDropMenu(false);
        } 
        confirmBox({ message , onYesClick});
    }


    return (
        <div className="">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            <div className='flex items-center gap-2'>
                                <input 
                                type="checkbox" 
                                id='select-all' 
                                onChange={handleSelectAll}
                                checked={selectAll}
                                />
                                <label htmlFor="select-all" >
                                    Select All
                                </label>
                            </div>
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Full Name
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Email
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Phone Number
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Status
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                   {
                        users?.map((item , i) => (
                            <tr
                            key={item._id} 
                            className="bg-white border-b transition duration-300 ease-in-out"
                            >
                            <td className=" text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <div className='flex items-center gap-2'>
                                    <input 
                                    id={item._id}
                                    type="checkbox" 
                                    checked={item?.status !== 'deleted' && selectedUsers.includes(item._id)}
                                    onChange={(e) => selectUserHandler(e , item._id)}
                                    disabled={item?.status === 'deleted'}
                                    />
                                    <label htmlFor={item._id}>
                                        <i 
                                        className="uil uil-mobile-android-alt text-base"
                                        ></i>
                                    </label>
                                </div>                                
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                <Link 
                                to={`/user-management/users/${item._id}`}
                                className='text-primary underline'
                                >
                                    {item?.fullName}
                                </Link>
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {
                                    user?.roles?.includes('admin')
                                    ?
                                        item?.email
                                    :
                                        hideString(item?.email , 'email')
                                }
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {
                                    user?.roles?.includes('admin')
                                    ?
                                        item?.phone
                                    :
                                        hideString(item?.phone , 'number')
                                }
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                <RequestStatus status={item?.status} />
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                    {/* DROP MENU */}
                                    {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                            {
                                                item?.status === 'active' || item?.status === 'inActive'
                                                ?
                                                <Link 
                                                to={`/user-management/edit-user/${item._id}`} 
                                                className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'>
                                                    <span>Edit User</span>
                                                </Link>
                                                : 
                                                    ''
                                            }
                                            {
                                                item?.status !== 'deleted' &&  
                                                <button 
                                                className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'
                                                onClick={() => 
                                                    blockUserHandler(
                                                        item?._id , 
                                                        item?.status === 'blocked' ? item?.kyc ? 'active' : 'inActive' : 'blocked'
                                                    )
                                                }
                                                disabled={blockLoading || deleteLoading}
                                                >
                                                    {
                                                        blockLoading 
                                                        ? 
                                                            <ClipLoader size={15} />
                                                        : 
                                                            <span>
                                                                {
                                                                    item?.status === 'blocked'
                                                                    ? 
                                                                        'Unblock User'
                                                                    : 
                                                                        'Block User'
                                                                }
                                                            </span>
                                                    }
                                                </button>
                                            }
                                            <button
                                            onClick={() => deleteHandler(
                                                item?._id , 
                                                item.status === 'deleted' ? item?.kyc ? 'active' : 'inActive' : 'deleted'
                                            )} 
                                            className='py-3 font-medium hover:bg-gray-100 cursor-pointer'
                                            disabled={deleteLoading || blockLoading}
                                            >                                           {
                                                    deleteLoading
                                                    ? 
                                                        <ClipLoader size={15} />
                                                    : 
                                                        <span>
                                                        {
                                                            item.status === 'deleted'
                                                            ? 
                                                                'Undo Delete'
                                                            : 
                                                                'Delete'
                                                        }
                                                        </span>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
        </div>
    )
}

export default memo(UsersTable);