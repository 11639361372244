import { baseURL } from "config/api";
import React from "react";
import { useNavigate } from "react-router-dom";

const InsurancePlan = ({ booking }) => {
    const navigate = useNavigate();
    
    return (
        <div>
            <div
            className={`
                rounded-md p-3 flex flex-col items-center gap-1 border cursor-pointer hover:border-primary 
            `}
            key={booking?._id}
            onClick={() => navigate(`/insurance-management`)}
            >
                <p className="text-primary font-medium text-sm">{booking?.insurance?.name}</p>
                <div>
                    <img
                        src={baseURL + "/insurance/" + booking?.insurance?.image}
                        alt={booking?.insurance?.name}
                        className="w-[50px] h-[50px] rounded-full object-cover border flex-shrink-0"
                    />
                </div>
                <p className="font-medium">₹{booking?.insuranceCost}</p>
                <p
                    className="text-sm"
                    data-tooltip-id="insurance-desc"
                    data-tooltip-content={`${booking?.insurance?.description}`}
                >
                    {booking?.insurance?.description}
                </p>
            </div>
        </div>
    );
};

export default InsurancePlan;
