function hideString(str, type) {
    if (type === "email") {
        // Email hiding logic
        const emailParts = str.split("@");
        const localPart = emailParts[0];
        const domainPart = emailParts[1];
        const hiddenLocal = `${localPart[0]}****${localPart[localPart.length - 1]}`;
        return `${hiddenLocal}@${domainPart}`;
    } else if (type === "number") {
        // Phone number hiding logic (showing only last 4 digits)
        return `******${str.slice(-4)}`;
    } else {
        // General string hiding logic
        if (str.length <= 6) {
            // If string is too short to hide
            return str;
        }
        const start = str.slice(0, 3); // first 3 characters
        const end = str.slice(-3);     // last 3 characters
        return `${start}******${end}`;
    }
}

export default hideString;