import RequestStatus from 'components/global/RequestStatus';
import Axios from 'config/api';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { setUpdateLoading, updateDoc } from 'redux/reducers/securityDepositsReducer';
import confirmBox from 'utils/confirmBox';
import toastError from 'utils/toastError';
import moment from 'moment';

const SecurityDepositsTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { docs  , updateLoading  } = useSelector(state => state.securityDeposit);
    const { user } = useSelector(state => state.auth);
    const [selectedDocId , setSelectedDocId] = useState(null);


    const updateHandler = async (deposit) => {
        setSelectedDocId(deposit?._id);
        const message = 'Are you sure. You want to refund this security deposit amount?';
        const onYesClick = async () => {
            dispatch(setUpdateLoading(true))
            try {
                const { data : { data : { doc , message } } } = await Axios(`/security-deposit/refund/${deposit?._id}` , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message)
                dispatch(updateDoc(doc));
                dispatch(setUpdateLoading(false));
            } catch (err) {
                dispatch(setUpdateLoading(false));
                toastError(err)
            }
        }

        confirmBox({ message , onYesClick })
    }

    
    return (
        <div className="overflow-x-auto rounded-lg">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-sm">
                    <tr className='bg-secondary text-white'>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Booking ID
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            User
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            Amount
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-left">
                            status
                        </th>
                        <th scope="col" className=" font-medium px-6 py-4 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className='text-sm'>
                {
                    docs?.map((doc , i) => (
                        <tr
                        key={doc?._id} 
                        className="bg-white border-b transition duration-300 ease-in-out"
                        >
                        <td 
                        className="text-gray-900  px-6 py-4 whitespace-nowrap underline">
                            <Link 
                            to={`/booking-management/booking-details/${doc?.booking}`}
                            title='View Booking'
                            >
                                {doc?.booking}
                            </Link>
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            <Link 
                            to={`/user-management/users/${doc?.user?._id}`}
                            className='underline'
                            >
                                {doc?.user?.fullName}
                            </Link>
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {doc?.amount}
                        </td>
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            <RequestStatus status={doc?.status} />
                        </td>
                        
                        <td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                            <div className='flex items-center justify-center'>

                                {
                                    doc?.status === 'on hold'
                                    ?
                                        <button 
                                        className='btn-primary py-2 px-4'
                                        title='Refund Amount'
                                        onClick={() => updateHandler(doc)}
                                        disabled={updateLoading}
                                        >
                                            {
                                                updateLoading && selectedDocId === doc?._id 
                                                ?
                                                    <ClipLoader size={20} />
                                                : 
                                                    'Refund Amount'
                                            }
                                        </button>
                                    : 
                                        moment(doc?.refundedAt).format('DD MMM YYYY hh:mm A')
                                }
                            </div>
                        </td>
                    </tr>
                    ))
                }
                
                </tbody>
            </table>
        </div>
    )
}

export default SecurityDepositsTable;