import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function CheckboxGroup(props) {
    const { label, name, options, formik , optionsContainerClassName, ...rest } = props;

    return (
        <div className=''>
            {label && <label className='label'>{label}</label>}
            <div className='mt-2'>
                <Field name={name}>
                    {({ field }) => {
                        return (
                            <div className={`${optionsContainerClassName}`}>
                                {
                                    options.map(option => {
                                        return (
                                            <div className='flex items-center  gap-2' key={option.value}>
                                                <input
                                                    type='checkbox'
                                                    id={option.value}
                                                    {...rest}
                                                    value={option.value}
                                                    checked={field.value.includes(option.value)}
                                                    onChange={() => {
                                                        if (field.value.includes(option.value)) {
                                                            const nextValue = field.value.filter(value => value !== option.value);
                                                            formik.setFieldValue(name, nextValue);
                                                        } else {
                                                            const nextValue = [...field.value, option.value];
                                                            formik.setFieldValue(name, nextValue);
                                                        }
                                                    }}
                                                    className={`${formik.touched[name] && formik.errors[name] ? 'border-red' : ''}`}
                                                />
                                                <label className='font-medium text-sm text-gray-500' htmlFor={option.value}>
                                                    {option.key}
                                                </label>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    }}
                </Field>
            </div>
            <ErrorMessage component={TextError} name={name} />
        </div>
    );
}

export default CheckboxGroup;
